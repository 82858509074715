<template>
  <div class="video-v2-block">
    <div class="container container-small">
      <div class="video-v2-block__content">
        <h2 v-if="title" v-html="title" class="video-v2-block__title"/>
        <div class="video-v2-block__media">
          <nuxt-img
            :src="String(imagePreviewLink)"
            width="756"
            fit="cover"
            loading="lazy"
            @click="changeVideoVisible"
          />
          <div
            v-if="isVideoVisible"
            v-html="videoHtml"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from "#sitis/internal/models/common";
import type { ComputedRef } from 'vue';

type IVideoBlockProps = Omit<IBlockDefaultProps, 'values'> & {
  values: any;
  vars: any;
}
const props = defineProps<IVideoBlockProps>();
const {
  $viewport
} = useNuxtApp();

const title: ComputedRef<string> = computed(() => {
  return props.values?.title || ""
});
const videoHtml: ComputedRef<string> = computed(() => {
  return props.values.video_html || ""
});
const imagePreviewLink: ComputedRef<string> = computed(() => {
  return $viewport.isGreaterOrEquals('lg') ? String(props.values?.image || '') : String(props.values?.image_mobile || props.values?.image || '')
});

const isVideoVisible = ref(false);
const changeVideoVisible = () => {
  isVideoVisible.value = true;
};
</script>

<style lang="scss">
.video-v2-block {
  margin: 50px 0;
}
.video-v2-block__content {
  background: hsla(var(--secondary-bg)/1);
  border-radius: 32px;
  padding: 16px;
  padding-top: 26px;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px 0px hsla(var(--black)/0.05);
}
.video-v2-block__title {
  margin-bottom: 24px;

  font-size: 36px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.03em;
  text-align: center;
  color: hsla(var(--white)/1);
}
.video-v2-block__media {
  position: relative;
  overflow: hidden;
  border-radius: 32px;

  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  div {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
  }
  div iframe {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
  }
  &:after {
    content: "";
    float: left;
    padding-top: 43%;
  }
}

@media (max-width: 1023px) {
  .video-v2-block__content {
    padding: 8px;
    padding-top: 20px;
  }
  .video-v2-block__title {
    margin-bottom: 18px;
    font-size: 24px;
  }
}
@media (max-width: 859px) {
  .video-v2-block__content {
    border-radius: 24px;
  }
}
</style>
